import { Button, Tooltip, IconButton } from '@mui/material'

export const ButtonIconComponent = ({title, icon, onClick, color, disabled,className}) => {
  
    const handleClick = (e) => {
      if(onClick) {
        onClick(e);
      }
    }
    
    return (
        <Tooltip title={title || ''} placement="top">
          <IconButton className={className || 'icon-blue'} onClick={handleClick} size="small" disabled={disabled || false}>
            {icon}
          </IconButton>
        </Tooltip>
    );
}

export const ButtonComponent = ({title, icon, onClick, className, color, disabled, fullWidth = false}) => {
  
  const handleClick = (e) => {
    if(onClick) {
      onClick(e);
    }
  }

  return (
      <Button 
      sx={{textTransform: 'none'}} 
      color={color || 'primary' } 
      fullWidth={fullWidth} 
      disabled={disabled || false} 
      className={color || (disabled ? 'button-disabled' : className)} 
      variant="contained" 
      startIcon={icon} 
      onClick={handleClick} 
      size="small"
      > 
      { title } 
      </Button>
  );
}


export const ButtonIcon = ({title, icon, onClick, color}) => {
  
  const handleClick = (e) => {
    if(onClick) {
      onClick(e);
    }
  }
  
  return (
      <Tooltip title={title || ''} placement="top">
        <i className={icon}  style={{ fontSize: '20px', cursor: 'pointer', color: color, marginTop: '5px', marginBottom: '5px' }} onClick={handleClick}></i>
      </Tooltip>
  );
}